import http from "../infra/config/httpClient";

const list = (page, size, orderBy, filters) => {
  return http.get("/api/v2/admin/master-room/games", {
    params: {
      page,
      size,
      orderBy,
      ...filters,
    },
  });
};

const cloneGame = (cloneGameRequest) => {
  return http.post("/api/v2/masters-rooms-games/clone", cloneGameRequest);
};

export default {
  list,
  cloneGame,
};
