<template>
  <v-dialog
    v-bind="$attr"
    fullscreen
    :overlay="false"
    max-width="300px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-text>
        <v-card-title primary-title class="px-0"> Parâmetros </v-card-title>
        <v-form @submit.prevent>
          <div class="">
            <p class="mb-2">PIN do jogo</p>
            <v-text-field
              type="number"
              v-model="form.gamePin"
              variant="outlined"
              density="compact"
              required
            ></v-text-field>
          </div>
          <div class="">
            <p class="mb-2">Email conta</p>
            <v-text-field
              v-model="form.userEmail"
              variant="outlined"
              density="compact"
              required
            ></v-text-field>
          </div>
          <div>
            <v-btn block color="primary" @click="onSubmit">Clonar jogo</v-btn>
            <v-btn block color="white" class="my-2" @click="onReset"
              >Resetar</v-btn
            >
            <v-btn block color="white" class="my-2" @click="onClose"
              >Cancelar</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "vue";

export default {
  name: "GameCloneModal.vue",
  emits: ["onSubmit", "onClose"],
  setup(props, ctx) {
    const form = ref({
      gamePin: null,
      userEmail: null,
    });

    function onClose() {
      ctx.emit("onClose");
    }

    function onReset() {
      form.value = {};
    }
    function onSubmit() {
      if (!form.value.gamePin || !form.value.userEmail) {
        return;
      }
      ctx.emit("onSubmit", { ...form.value });
      onReset();
      onClose();
    }

    return { form, onSubmit, onClose, onReset };
  },
};
</script>
